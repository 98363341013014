import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Page from "../components/atoms/page";
import * as PropTypes from "prop-types";
import {graphql} from 'gatsby'

const propTypes = {
    data: PropTypes.object.isRequired,
};

const storeTemplate = ({data}) => {
    const cData = data.contentfulStores;

    return (
        <Layout useMain={true}>
            <SEO
                title={cData.storeName}
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />
            <Page title={cData.storeName}>
                Store
                <p>phone: {cData.phoneNumber}</p>
                <p>rating: {cData.rating}</p>
                <p>coordinates: {cData.coordinates.lat}</p>
                <p>visible: {cData.visibleStoresMap}</p>
                <p>specialUrl: {cData.specialUrl}</p>
                <p>orderUrl: {cData.orderUrl}</p>
                <p>menuUrl: {cData.menuUrl}</p>
            </Page>
        </Layout>
    );
}

storeTemplate.propTypes = propTypes;

export default storeTemplate;

export const pageQuery = graphql`
  query contentfulStoresItem($contentful_id: String!) {
    contentfulStores(contentful_id: {eq: $contentful_id}, node_locale: {eq: "en-US"}) {
         storeId
         contentful_id
         storeName
         storeId
         specialUrl
         spaceId
         rating
         phoneNumber
         orderUrl
         menuUrl
         address {
           city
           country
           state
           streetName
           streetName2
           streetNumber
           zipCode
           streetSuffix
         }
         visibleStoresMap
         coordinates {
           lat
           lon
         }
         
    }
  }
`;
